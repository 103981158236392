body{
  font-family: 'Ubuntu','Helvetica Neue',Helvetica,Arial,sans-serif;
  line-height: 0;
  letter-spacing: 1.2px;
  margin-bottom: 0px;
  margin-top: 7px;
  color: #dddddd;
  display: inline;
  background-color: #ffffff;
  background-size: 400% 400%;
}

img { pointer-events: none; }

* {box-sizing: border-box;}

.gui {
  color: rgb(255,255,255);
  position: relative;
  width: 282px;
  height: 140px;
  padding: 5px;
  margin-bottom: 10px;
}

.craft-gui {
  background-image: url(Interface.png);
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.craft-table {
  position: relative;
  top: 14px;
  left: 10px;
  width: 108px;
  height: 108px;
}

.craft-table.--item {
  width: 252px;
}

.craft-fire {
  position: relative;
  top: 10px;
  left: 50px;
  width: 36px;
}

.craft-fire > .itemicon:nth-child(2) {
  margin-top: 36px;
}

.craft-fire > .craft-arrow {
  margin-left: 70px;
  margin-top: -65px;
}

.craft-fire > .craft-out {
  position: relative;
  margin-top: -30px;
  margin-left: 135px;
}

.craft-arrow {
  background: url(Arrow.png) no-repeat;
  width: 32px;
  height: 26px;
  position: relative;
  margin-top: -55px;
  margin-left: 150px;
  cursor: help;
  pointer-events: auto;
}

.craft-arrow.check {
  cursor: pointer;
}

.craft-firegif {
  background: url(Fire.gif) no-repeat;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 36px;
  margin-left: 2px;
}

.craft-form {
  width: 32px;
  height: 26px;
  position: absolute;
  margin-top: 20px;
  margin-left: 235px;
  cursor: help;
  pointer-events: auto;
}

.craft-out {
  position: relative;
  margin-top: -30px;
  margin-left: 210px;
}

.craft-flex {
  display: flex;
  justify-content: center;
  line-height: 0;
}

.craft-input {
  text-align: center;
  padding-bottom: 10px;
  margin: 0px 10px 0px 10px;
}

.gc-list {
  display: flex;
  justify-content: center;
}

.gc-list > div {
  width: 100%;
  padding: 8px 12px 8px 12px;
  text-align: left;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
}

.gc-list > div:hover {
  color: #a7a7a7;
}

.gc-name {
  text-shadow: 2px 2px 0px rgba(148,148,148,0.3);
}

.gcprofile {
  right: 0;
}

.gchelp {
  left: 0;
}

.gchelpf {
  display: flex;
  justify-content: center;
  margin-left: 100px;
}

.gchelpf > div {
  width: 100%;
  padding: 8px 8px 8px 8px;
  text-align: left;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
}

.gchelpf > div:hover {
  color: #a8a8a8;
}

.gcmenu {
  width: auto;
  height: auto;
  position: absolute;
  top: 57px;
  border-radius: 5px;
  background: #f5f5f5;
  min-width: 120px;
  min-height: 36px;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  z-index: 100;
}

.gcmenu > div:first-child {
  border-bottom: 1px solid #dee2e6!important;
}

.gcmenu > div:last-child:not(:nth-child(2)) {
  border-top: 1px solid #dee2e6!important;
}

.gcmenu > div {
  cursor: pointer;
  width: 100%;
  line-height: 20px;
  padding: 8px 12px 8px 12px;
  text-align: left;
}

.gcmenu > div:hover {
  background-color: #e6e6e6;
}

.gcmenu > div:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.gcmenu > div:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.btn.btn-gc {
  border: 0;
  width: 34px;
  height: 34px;
  font-size: 0;
  margin-left: auto;
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.btn.btn-gc:active {
  text-decoration: none;
}
.btn.btn-gc:hover {
  text-decoration: none;
  background-position: 0 34px;
}
.btn-gc-name {
  cursor: pointer;
}

.h-search {
  margin: 0;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  right: 0px;
  margin-left: auto;
}

input {
  outline:none;
}

input.inputtext {
  background: #ececec;
  padding-left: 15px;
  padding-right: 15px;
  border: 0;
  border-radius: 5px;
  font-size: 14px;
  height: 37px;
  margin: 0;
}

input.inputtext:hover, input.inputtext:focus  {
  background: #e4e4e4;
}

input.search {
  background: #f5f5f5;
  padding-left: 15px;
  border: 0;
  border-radius: 5px;
  font-size: 14px;
  height: 37px;
  margin: 0;
  margin-left: auto;
}

input.search:hover, input.search:focus  {
  background: #ececec;
}

input:disabled {
  background: #ececec;
  cursor: not-allowed;
}

.disabled {
  background: #e4e4e4 !important;
  cursor: not-allowed !important;
}

.Page-Block.--Shadow {
  min-height: 10px;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.Page-Block.--Welcome {
  justify-content: center;
  min-height: 10px;
  background: none;
}

.Page-Block.--Top {
  margin: 0;
  min-height: 10px;
  background: none;
}

.Page-Block.--Bottom {
  margin: 0;
  min-height: 10px;
  background: none;
}

.Page-Block.--Block-Shadow.--Case {
  background-color: #e4d6bd;
  border: 8px solid;
  border-radius: 16px;
  border-color: #99a181;
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, .35) inset;
}

.Page-Block.--Block-Shadow.--Case > .--WD {
  color: #000;
}

.Page-Block.--Chat {
  position: relative;
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, .35);
}

.Page-Desc.--AddList.--Case > div {
  background: linear-gradient(to top, #fef9f1, #dad3c5);
  border: 2px solid;
  border-radius: 4px;
  border-color: #8b8473;
  box-shadow: -1px -1px 4px 1px rgba(0, 0, 0, .35) inset;
}

.Page-Desc.--AddList.--Case > div:hover {
  background: linear-gradient(to top, #f3ece2, #c8bfae);
}

.Page-Desc.--AddList.--Case > div > div:first-child {
  margin-left: -10px;
}

.Page-Desc.--Chat {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px 10px 0px 10px;
  text-align: left;
}

.Page-Desc.--Chat > div {
  flex: 100%;
  max-width: 100%;
}

.Page-Desc.--Chat > div:last-child {
  animation-duration: 0.5s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    opacity: 0;
    height: 0;
  }

  to {
    height: auto;
    opacity: 1;
  }
}

.craft-list {
  font-size: 10px;
  display: flex;
  flex-wrap: wrap;
}

.craft-list-name {
  position: relative;
}

.itemcount {
  font-size: 12px;
  position: absolute;
  margin-top: -8px;
  right: 2px;
}

.itemicon {
  display: inline-block;
  border: 2px solid;
  border-color: #373737 #FFF #FFF #373737;
  background-color: #8B8B8B;
  width: 36px;
  height: 36px;
  cursor: pointer;
  overflow: visible !important;
  white-space: nowrap;
}

.itemicon-large {
  display: inline-block;
  border: 2px solid;
  border-color: #373737 #FFF #FFF #373737;
  background-color: #8B8B8B;
  width: 44px;
  height: 44px;
  margin-top: -.30em;
  cursor: pointer;
}

.itemicon[dc="2"] {
  min-width: 36px;
  background: linear-gradient(to top left, #a1b296, #bccdb0);
  border-radius: 4px;
  border-color: #485743;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .35) inset;
}

.itemicon[dc="2"] > .itemimg > .itemcount {
  font-size: 12px;
  position: absolute;
  margin-top: -19px;
  right: 1px;
  color: #fff;
}

.itemicon[dc="2"]::after, .itemicon[dc="2"]::before {
  content: none;
}

.itemicon[dc="2"] .tooltip {
  margin-left: 12px;
  margin-top: -1em;
}

.itemicon .tooltip, .itemicon-large .tooltip {
  background: rgba(18,0,15,0.9);
  border: 3px solid rgba(43,8,98,0.9);
  border-radius: 0;
  box-shadow: 0 3px rgba(18,0,15,0.9), 3px 0 rgba(18,0,15,0.9), 0 -3px rgba(18,0,15,0.9), -3px 0 rgba(18,0,15,0.9);
  box-sizing: content-box;
  cursor: help;
  display: none;
  float: none;
  height: auto;
  margin-left: 38px;
  margin-top: -2em;
  moz-box-sizing: content-box;
  o-text-overflow: ellipsis;
  overflow: hidden;
  padding: 5px;
  position: absolute;
  text-overflow: ellipsis;
  webkit-border-radius: 0;
  webkit-box-shadow: 0 3px rgba(18,0,15,0.9),3px 0 rgba(18,0,15,0.9),0 -3px rgba(18,0,15,0.9),-3px 0 rgba(18,0,15,0.9);
  webkit-box-sizing: content-box;
  width: 300px;
  max-width: 300px;
  line-height: 1;
  overflow: visible !important;
  white-space: nowrap;
}

.itemicon:hover .tooltip, .itemicon-large:hover .tooltip {
  display: inline;
}

.container:active .slick-list, .container:active .slick-track {
  transform: none !important;
}


.tooltip p, .tooltip div.grt {
  font: bold normal normal 14px Tahoma,Geneva,sans-serif;
  letter-spacing: 1px;
  margin-bottom: 0px;
  margin-top: 4px;
  white-space: pre-wrap;
}

.slick-track {
  z-index: 6;
}

.container:hover {
  position: relative;
  z-index: 98;
}

.slick-list {
  overflow: visible !important;
  white-space: nowrap;
  z-index: 6;
}

.itemimg {
  position: relative;
  width: 32px;
}

.itemimg > img {
  width: 32px;
  height: 32px;
}

.itemicon-large > .itemimg {
  margin-left: 4px;
  margin-top: 4px;
}

.itemicon::before, .itemicon::after, .itemicon-large::before, .itemicon-large::after {
  content: "";
  position: absolute;
  background-color: #8B8B8B;
  height: 2px;
  width: 2px;
  pointer-events: none;
}

.itemicon::before {
  margin-left: -2px;
  margin-top: 32px;
}

.itemicon::after {
  margin-left: 32px;
  margin-top: -34px;
}

.itemicon-large::before {
  margin-left: -2px;
  margin-top: 40px;
}

.itemicon-large::after {
  margin-left: 40px;
  margin-top: -38px;
}

.itemhref {
  color: #737373;
}

.itemhref:hover:active {
  color: #737373;
}

.casename {
  display: flex;
  justify-content: center;
}

.caseimg {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.caseic {
  background: #dfdfdf;
  margin-left: 2px;
  padding: 4px;
  display: inline-block;
  vertical-align: bottom;
  border-radius: 5px;
  line-height: 15px;
}

.gr {
  position: absolute;
  top: 0px;
  background: url(gr.png);
  height: 34px;
  width: 100%;
}

.gr-1 {
  background-position-x: -64px;
}

.gr-2 {
  background-position-x: -128px;
}

.gr-3 {
  background-position-x: -192px;
}

.gr-4 {
  background-position-x: -256px;
}

.skin-f-4x {
  image-rendering: optimizeSpeed;
  /* Legal fallback */
  image-rendering: -moz-crisp-edges;
  /* Firefox        */
  image-rendering: -o-crisp-edges;
  /* Opera          */
  image-rendering: -webkit-optimize-contrast;
  /* Safari         */
  image-rendering: optimize-contrast;
  /* CSS3 Proposed  */
  image-rendering: crisp-edges;
  /* CSS4 Proposed  */
  image-rendering: pixelated;
  /* CSS4 Proposed  */
  -ms-interpolation-mode: nearest-neighbor;
  /* IE8+           */
  background-size: 256px;
  width: 32px;
  height: 32px;
  background-position: -32px -32px;
  overflow: hidden;
  display: inline-block;
}

.skin-f-4x::after {
  content: " ";
  width: 32px;
  height: 32px;
  background-size: 288px;
  background-image: inherit;
  background-position: -182px -38px;
  overflow: hidden;
  display: inline-block;
}

.skin-f-8x {
  image-rendering: optimizeSpeed;
  /* Legal fallback */
  image-rendering: -moz-crisp-edges;
  /* Firefox        */
  image-rendering: -o-crisp-edges;
  /* Opera          */
  image-rendering: -webkit-optimize-contrast;
  /* Safari         */
  image-rendering: optimize-contrast;
  /* CSS3 Proposed  */
  image-rendering: crisp-edges;
  /* CSS4 Proposed  */
  image-rendering: pixelated;
  /* CSS4 Proposed  */
  -ms-interpolation-mode: nearest-neighbor;
  /* IE8+           */
  background-size: 512px;
  width: 64px;
  height: 64px;
  background-position: -64px -64px;
  overflow: hidden;
}

.skin-f-8x::after {
  content: " ";
  width: 64px;
  height: 64px;
  background-size: 576px;
  background-image: inherit;
  background-position: -364px -76px;
  overflow: hidden;
  display: inline-block;
}

.frame_face {
  display: flex;
  justify-content: center;
  align-items:center;
  background-image: url('../global/frame_face.png');
  -moz-background-size: 100%;
  -webkit-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;
  width: 110px;
  height: 110px;
  image-rendering: optimizeSpeed;
  /* Legal fallback */
  image-rendering: -moz-crisp-edges;
  /* Firefox        */
  image-rendering: -o-crisp-edges;
  /* Opera          */
  image-rendering: -webkit-optimize-contrast;
  /* Safari         */
  image-rendering: optimize-contrast;
  /* CSS3 Proposed  */
  image-rendering: crisp-edges;
  /* CSS4 Proposed  */
  image-rendering: pixelated;
  /* CSS4 Proposed  */
  -ms-interpolation-mode: nearest-neighbor;
  /* IE8+           */
}

.fw {
  font-weight: 600;
}

.Chosen {
  position: absolute;
  right: 10px;
  margin-top: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  filter: grayscale(100%);
}

.Chosen:hover,.Chosen.add:hover {
  filter: grayscale(60%);
}

.Chosen.add {
  filter: grayscale(0%);
}

.gcmenu > .chosen::before {
  content: "";
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  background: url(Chosen.png) no-repeat;
  background-size: cover;
  top: 3px;
  margin-right: 5px;
}

.gcmenu > .imgcase::before {
  content: "";
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  background: url(List_item.png) no-repeat;
  background-size: cover;
  top: 4px;
  margin-right: 5px;
}

.gcmenu > .imgcraft::before {
  content: "";
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  background: url(List_wb.png) no-repeat;
  background-size: cover;
  top: 4px;
  margin-right: 5px;
}

.gcmenu > .users::before {
  content: "";
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  background: url(List_users.png) no-repeat;
  background-size: cover;
  top: 4px;
  margin-right: 5px;
}

.pref-MOD {
  color: #ab0000;
}

.pref-ADMIN {
  color: #ffd400;
}

.pref-GC {
  color: #20877d;
}

.Page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: baseline;
}

.Page-Loader {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  width: 350px;
  height: 100%;
  text-align: center;
  left:0;
  right:0;
  margin: 0 auto;
  color: #c7c7c7;
  line-height: 40px;
}

.Page-Home {
  position: relative;
  float: left;
  width: 80%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 1024px;
  min-width: 650px;
  font-size: 20px;
  left:0;
  right:0;
  top:34px;
  margin: 0 auto;
  transition: all 0.5s ease-out;
  color: #949494;
}

.chat-bg .Page-Home {
  color: #ffffff;
}

.--ListTest {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 15px;
  margin: 0px 10px 10px 10px;
}

.--ListTest > img {
  margin-right: 5px;
}

.Page-Desc.--AddList, .Page-Desc.--SaveList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px 10px 0px 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Page-Desc.--AddList.--Case,.Page-Desc.--AddList.--Cases {
  justify-content: left;
}

.Page-Desc.--AddList.--Case > div {
  flex: 0 0 50%;
  max-width: calc(50% - 10px);
}

.Page-Desc.--AddList.--Cases > div {
  flex: 0 0 25%;
  max-width: calc(25% - 10px);
}

.Page-Desc.--AddList > div {
  line-height: 20px;
  flex: 0 0 25%;
  max-width: calc(25% - 10px);
  padding: 5px 15px 5px 15px;
  font-size: 16px;
  text-align: left;
  background: #ececec;
  margin: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.Page-Desc.--AddList > div > div {
  overflow: hidden;
  text-overflow: ellipsis;
}

.Page-Desc.--SaveList > div {
  width: 282px;
  line-height: 20px;
  padding: 5px 15px 5px 15px;
  font-size: 16px;
  text-align: left;
  background: #ececec;
  margin: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Page-Desc.--AddList > div:hover, .Page-Desc.--SaveList > div:hover {
  background: #e4e4e4;
}

.Page-Desc.--AddList > div > div, .Page-Desc.--SaveList > div > div {
  margin-left: 10px;
}

.success {
  background: #8ad2cc !important;
}

.destroy {
  background: #a94442 !important;
}

.helphome {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.helphome > div {
  line-height: 20px;
  flex: 0 0 33.3%;
  max-width: calc(33.3% - 7.5px);
  padding: 5px;
  font-size: 16px;
  text-align: left;
}

.Page-Desc.--Welcome {
  font-size: 45px;
  font-weight: 600;
  line-height: 45px;
  margin: 20px;
}

.Page-Desc.--WD {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  margin: 0px 20px 0px 20px;
}

.Page-Desc.--Desc {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  margin: 0px 20px 0px 20px;
}

.Page-Desc.--Button {
  display: flex;
  justify-content: center;
  user-select: none;
}

.Page-Desc.--Button > div {
  font-size: 22px;
  font-weight: 600;
  line-height: 22px;
  margin: 0px 20px 0px 20px;
  border-radius: 10px;
  background-color: #5cb85c;
  border-color: #4cae4c;
  color: white;
  padding: 5px;
  width: 300px;
  cursor: pointer;
}

.Page-Desc.--Button > div:hover {
  background-color: #50a150;
  border-color: #50a150;
}

.h-list.--history:last-child .--history.id {
  cursor: default;
}

.h-list:not(:last-child) {
  color: #737373;
}

.h-list.--history:last-child .--history.id:hover {
  text-decoration: none;
}

.hh-n {
  font-size: 19px;
  font-weight: 600;
}

.Page-Top, .Page-Body, .Page-Bottom {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.Page-Top {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 600;
}

.Page-Body {
  flex-wrap: wrap;
  flex-direction: column;
}

.Page-Bottom {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-top: 1px solid #dee2e6!important;
}

.Page-Block {
  margin: 20px auto;
  border-radius: 5px;
  background: #f5f5f5;
  width: 100%;
  min-width: 280px;
  height: auto;
  min-height: 100px;
}

.Page-Theme {
  text-transform: uppercase;
  margin-left: 5%;
  width: 90%;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(255, 255, 255, 0.3);
}

.Page-Search {
  text-align: left;
  font-size: 15px;
  padding: 8px 12px 8px 12px;
  flex: 0 0 50%;
  line-height: 20px;
  max-width: 100%;
  cursor: pointer;
}

.Page-Search:first-letter {
  font-weight: 600;
}

.Page-Search:first-child {
  border-top-left-radius: 5px;
}

.Page-Search:first-child:last-child {
  border-top-right-radius: 5px;
}

.Page-Search:nth-child(2) {
  border-top-right-radius: 5px;
}

.Page-Search:nth-last-child(-n+2):not(:nth-child(even)) {
  border-bottom-left-radius: 5px;
}

.Page-Search:last-child {
  border-bottom-right-radius: 5px;
}

.Page-Search:hover {
  background-color: #e6e6e6;
}

.Page-Desc {
  text-align: center;
  font-size: 15px;
  padding: 10px 0px 10px 0px;
}

.Page-Desc.--Shadow {
  line-height: 20px;
  margin: 20px;
}

.Page-Desc.--Shadow.--FC {
  font-size: 25px;
  color: #737373;
}

.Page-Desc.--History {
  text-align: left;
  margin: 5px 15px 5px 15px;
}

.Page-Desc.--AddCraft {
  text-align: left;
  margin: 15px;
}

.Page-Desc.--History > .h-list {
  line-height: 20px;
  display: inline-block;
}

.Page-Desc.--Top > .h-icon {
  position: absolute;
  font-size: 25px;
  color: #737373;
  cursor: pointer;
}

.Page-Desc.--Top > .h-icon:hover {
  color: #949494;
}

.Page-Desc.--Footer {
  line-height: 15px;
}

.auth-icon > a {
  position: absolute;
  margin-left: 200px;
  font-size: 15px;
  color: #737373;
  text-decoration: none;
}

.auth-icon > a:hover {
  text-decoration: none;
  color: #949494;
}

.Page-Desc.--Top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Page-Desc.--Bottom {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Page-Desc.--History > .h-list:not(:first-child):before {
  padding: 0 5px;
  color: #ccc;
  content: "/\00a0";
}


a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

span.id {
  cursor: pointer;
}

span.id:hover {
  text-decoration: underline;
}

.--job {
  line-height: 20px;
  font-size: 16px;
  text-align: left;
}

.craft-req {
  display: flex;
  align-items: center;
}

.craft-req > span {
  margin-left: 5px;
}

.craft-req.--fire > .imgfire::after {
  content: "";
  position: absolute;
  width: 16px;
  margin-top: 16px;
  margin-left: -10px;
  height: 16px;
  background-image: url(Fire.png);
  background-size: cover;
}

.pulse {
  animation-name: opacity;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@-webkit-keyframes opacity {
  0%, 100% {color:#949494}
  50% {color:#00887d;}
}

@-o-keyframes opacity {
  0%, 100% {color:#949494}
  50% {color:#00887d;}
}

@-moz-keyframes opacity {
  0%, 100% {color:#949494}
  50% {color:#00887d;}
}

@keyframes opacity {
  0%, 100% {color:#949494}
  50% {color:#00887d;}
}

.pulseb {
  animation-name: opacityb;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@-webkit-keyframes opacityb {
  0%, 100% {background-color:#8B8B8B}
  50% {background-color:#00887d;}
}

@-o-keyframes opacityb {
  0%, 100% {background-color:#8B8B8B}
  50% {background-color:#00887d;}
}

@-moz-keyframes opacityb {
  0%, 100% {background-color:#8B8B8B}
  50% {background-color:#00887d;}
}

@keyframes opacityb {
  0%, 100% {background-color:#8B8B8B}
  50% {background-color:#00887d;}
}

.pulses {
  animation-name: opacitys;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@-webkit-keyframes opacitys {
  0%, 100% {background-color:#f5f5f5}
  50% {background-color:#e6e6e6;}
}

@-o-keyframes opacitys {
  0%, 100% {background-color:#f5f5f5}
  50% {background-color:#e6e6e6;}
}

@-moz-keyframes opacitys {
  0%, 100% {background-color:#f5f5f5}
  50% {background-color:#e6e6e6;}
}

@keyframes opacitys {
  0%, 100% {background-color:#f5f5f5}
  50% {background-color:#e6e6e6;}
}


.pulseal {
  animation-name: opacityal;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@-webkit-keyframes opacityal {
  0%, 100% {background-color:#ececec}
  50% {background-color:#8ad2cc;}
}

@-o-keyframes opacityal {
  0%, 100% {background-color:#ececec}
  50% {background-color:#8ad2cc;}
}

@-moz-keyframes opacityal {
  0%, 100% {background-color:#ececec}
  50% {background-color:#8ad2cc;}
}

@keyframes opacityal {
  0%, 100% {background-color:#ececec}
  50% {background-color:#8ad2cc;}
}
.loader {
  display: block;
  position: fixed;
  width: 80px;
  height: 80px;
  margin-top: -50px;
}

.loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.loader div:nth-child(1) {
  animation-delay: -0.45s;
}
.loader div:nth-child(2) {
  animation-delay: -0.3s;
}
.loader div:nth-child(3) {
  animation-delay: -0.15s;
}

.container {
  display: inline-block;
  width: 36px;
  height: auto;
}

.slick-slide {
  opacity: 0;
}

.slick-active {
  opacity: 1;
}

.slick-slide, .slick-slide *{ outline: none !important; }

@media (max-width: 1800px) {
  .helphome > div, .helphome.--profile > div {
    flex: 0 0 50%;
    max-width: calc(50% - 5px);
  }
  .Page-Desc.--AddList.--Cases > div {
    flex: 0 0 50%;
    max-width: calc(50% - 10px);
  }
}

@media (max-width: 768px) {
  .Page-Search {
    flex: 0 0 100%;
  }
  .Page-Search:first-child {
    border-top-right-radius: 5px;
  }
  .helphome > div, .helphome.--profile > div {
    flex: 0 0 100%;
    max-width: calc(100% - 10px);
  }
  .Page-Desc.--AddList > div {
    flex: 0 0 100%;
    max-width: calc(100% - 10px);
  }
  .Page-Desc.--AddList.--Case > div {
    flex: 0 0 100%;
    max-width: calc(100% - 10px);
  }
  .Page-Desc.--AddList.--Cases > div {
    flex: 0 0 100%;
    max-width: calc(100% - 10px);
  }
}

@media (max-width: 721px) {
  .itemicon[dc="2"] .tooltip {
    max-width: 250px;
  }
  .openmenu:after {  
    left: -20px;
    top: -12px;
    color: transparent;
    background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNC4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0i0KHQu9C+0LlfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAzNCAzNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzQgMzQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiM3MzczNzM7fQ0KPC9zdHlsZT4NCjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0xMi4yLDIzLjFsNi41LTYuNWwtNi41LTYuNWwyLTJsOC41LDguNWwtOC41LDguNUwxMi4yLDIzLjF6Ii8+DQo8L3N2Zz4NCg==") no-repeat 50% 50% / 1em 1em;
    width: 1em;
    height: 1em;  
    content: "";
    position: absolute;
    transition: transform .5s;
    transform: rotateZ(90deg);
  }
  .menumini:after {
    transform: rotateZ(0deg);
  }
  .Page-Desc.--Shadow.--FC {
    font-size: 15px;;
  }
  .Page-Desc.--WD {
    font-size: 18px;
    line-height: 18px;
  }
  .Page-Desc.--Welcome {
    font-size: 42px;
    line-height: 42px;
  }
  .Page-Home {
      width: 80%;
      min-width: 285px;
  }
  input.search {
    width: 180px;
  }
  .Page-Loader {
    font-size: 30px;
    line-height: 30px;
  }
}

.user_form {
  display: flex;
  justify-content: center;
}

.user_input {
  margin-left: unset !important;
}

.user_button {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 26px;
  margin: 0px 20px 0px 20px;
  border-radius: 10px;
  background-color: #5cb85c;
  border-color: #4cae4c;
  color: white;
  padding: 5px;
  width: 100px;
  cursor: pointer;
}

.user_remove {
  border-radius: 30px;
  background: #d7d7d7;
  width: 25px;
  height: 25px;
  padding: 5px;
  transition: all 0.5s ease-in-out;
}

.user_remove:hover {
  transform: scale(1.05);
  background: #cd6b69;
}

.user_remove span {
  background: url(cancel.svg) no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
  display: block;
}

.link {
  color: #383838 !important;
}

.online {
  display: flex;
  margin-left: auto !important;
  width: 24px;
  height: 24px;
}

.online-0 {
  color: #a94442;
  background: url(offline.png) no-repeat;
  background-size: contain;
}

.online-1 {
  color: #5cb85c;
  background: url(online.png) no-repeat;
  background-size: contain;
}

@media (max-width: 421px) {
  .itemicon[dc="2"] .tooltip {
    max-width: 300px;
    left: -41px;
    margin-top: 40px;
  }
  .Page-Desc.--Top > .h-icon {
    font-size: 15px;
  }
}

@media (max-width: 360px) {
  .itemicon[dc="2"] .tooltip {
    max-width: 250px;
  }
}

.message {
  font: bold normal normal 14px Tahoma,Geneva,sans-serif;
  letter-spacing: 1px;
  margin-bottom: 0px;
  margin-top: 4px;
  white-space: pre-wrap;
  font-size: 14px !important;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.message.time {
  justify-content: right;
}

.message abbr {
  text-decoration: none;
  cursor: help;
  display: flex;
  flex-wrap: wrap;
}

.message .GC-text {
  color: #FFAA01; 
  text-shadow: 0.5px 1px 0px rgba(0, 0, 0,0.12);
}

.message .GC-wtext {
  color: rgba(255, 255, 255,1); 
  text-shadow: 2px 2px 0px rgba(0, 0, 0,0.2);
}

.message .GC-wgray {
  color: #a8a8a8;
  text-shadow: 2px 2px 0 rgb(168 168 168 / 20%);
}

.message .GC-wgreen {
  color: #05A801;
  text-shadow: 2px 2px 0 rgb(168 168 168 / 20%);
}

.message .GC-wpurple {
  color: #B428FA;
  text-shadow: 2px 2px 0 rgb(168 168 168 / 20%);
}

.message .GC-yellow {
  color: #ffdf42; 
  text-shadow: 1px 1px 0px rgb(72 72 72 / 32%);
}

.message .GC-prefix {
  color: rgba(255, 255, 255,1); 
  text-shadow: 2px 2px 0px rgba(0, 0, 0,0.2);
}

.message .GC-prefdown {
  margin-top: -2px;
}

.message .GC-nick {
  color: rgba(255, 255, 255,1); 
  text-shadow: 2px 2px 0px rgba(0, 0, 0,0.2);
}

.message .GC-nickdown {
  margin-top: -2px;
}

.message .GC-star {
  color: #BF4343;
  text-shadow: 2px 2px 0 rgb(168 168 168 / 20%);
  margin-bottom: -3px;
  display: flex;
  align-items: center;
}

.message.ch-t .GC-text {
  color: #ffdf42; 
  text-shadow: 1px 1px 0px rgb(72 72 72 / 32%);
}

.chat-bg {
  
}

.chat-bg2::before {
  content: "";
  display: block;
  background: url('./bg.png') no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}