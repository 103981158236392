.GC--all {
    color: rgba(255, 255, 255,1); text-shadow: 2px 2px 0px rgba(255, 255, 255,0.2);
}

.GC-cd {
    color: rgba(168,168,168,1); text-shadow: 2px 2px 0px rgba(168,168,168,0.2);
}

.GC-m-t {
    color: rgba(168,168,168,1); text-shadow: 2px 2px 0px rgba(168,168,168,0.2);
}

.GC-k-d {
    color: rgba(185, 216, 162,1); text-shadow: 2px 2px 0px rgba(185, 216, 162,0.2);
}

.GC-k-i {
    color: rgba(108, 165, 67,1); text-shadow: 2px 2px 0px rgba(108, 165, 67,0.2);
}

.GC-k-l {
    color: rgba(32, 176, 160,1); text-shadow: 2px 2px 0px rgba(32, 176, 160,0.2);
}

.GC-k-p {
    color: rgba(255, 36, 0,1); text-shadow: 2px 2px 0px rgba(255, 36, 0,0.2);
}

.GC-k-u {
    color: rgba(255, 20, 147,1); text-shadow: 2px 2px 0px rgba(255, 20, 147,0.2);
}

.GC-k-a {
    color: rgba(6, 165, 59,1); text-shadow: 2px 2px 0px rgba(6, 165, 59,0.2);
}

.GC-k-lc {
    color: rgba(32, 176, 160,1); text-shadow: 2px 2px 0px rgba(32, 176, 160,0.2);
}

.GC-k-w {
    color: rgba(207, 106, 50,1); text-shadow: 2px 2px 0px rgba(207, 106, 50,0.2);
}

.GC-k-h {
    color: rgba(0, 170, 0,1); text-shadow: 2px 2px 0px rgba(0, 170, 0,0.2);
}

.GC-k-as {
    color: rgba(140, 159, 255,1); text-shadow: 2px 2px 0px rgba(140, 159, 255,0.2);
}

.GC-k-ras {
    color: rgba(48, 80, 250,1); text-shadow: 2px 2px 0px rgba(48, 80, 250,0.2);
}

.GC-k-has {
    color: rgba(89, 60, 242,1); text-shadow: 2px 2px 0px rgba(89, 60, 242,0.2);
}

.GC-k-uas {
    color: rgba(190, 35, 255,1); text-shadow: 2px 2px 0px rgba(190, 35, 255,0.2);
}

.GC-u-t {
    color: rgba(255, 117, 24,1); text-shadow: 2px 2px 0px rgba(255, 117, 24,0.2);
}

.GC-k-un.--t {
    color: rgba(255, 117, 24,1); text-shadow: 2px 2px 0px rgba(255, 117, 24,0.2);
}

.GC-s-d {
    color: rgba(255, 255, 255,1); text-shadow: 2px 2px 0px rgba(255, 255, 255,0.2);
}
.GC-s-s {
    color: rgba(70, 153, 99,1); text-shadow: 2px 2px 0px rgba(70, 153, 99,0.2);
}

.GC-s-n {
    color: rgba(255,155,132,1); text-shadow: 2px 2px 0px rgba(255,155,132,0.2);
}

.GC-c-c {
    color: rgba(189, 165, 203,1); text-shadow: 2px 2px 0px rgba(189, 165, 203,0.2);
}

.GC-c-r {
    color: rgba(137, 93, 162,1); text-shadow: 2px 2px 0px rgba(137, 93, 162,0.2);
}

.GC-d-t {
    color: rgba(168,168,168,1); text-shadow: 2px 2px 0px rgba(168,168,168,0.2);
}

.GC-g-t {
    color: rgba(168,168,168,1); text-shadow: 2px 2px 0px rgba(168,168,168,0.2);
}

.gci-name {
    color: rgba(255, 255, 255,1); text-shadow: 2px 2px 0px rgba(255, 255, 255,0.2);
    margin-top: 2px !important;
    margin-bottom: 10px !important;
}

.GC-count {
    color: rgba(255, 255, 255,1); text-shadow: 2px 2px 0px rgba(255, 255, 255,0.2);
}

.GC-p-t {
    color: rgba(255, 255, 255,1); text-shadow: 2px 2px 0px rgba(255, 255, 255,0.2);
}
.GC--all.--t::before {
    content: '';
}

.GC-cd.--t::before {
    content: '';
}

.GC-k-d.--t::before {
    content: 'Обычный декоративный предмет';
}

.GC-k-i.--t::before {
    content: 'Улучшенный декоративный предмет';
}

.GC-k-l.--t::before {
    content: 'Легендарный декоративный предмет';
}

.GC-k-p.--t::before {
    content: 'Именной декоративный предмет';
}

.GC-k-u.--t::before {
    content: 'Неповторимый декоративный предмет';
}

.GC-k-un.--t::before {
    content: 'Уникальный декоративный предмет';
}

.GC-k-a.--t::before {
    content: 'Предмет администрации';
}

.GC-k-lc.--t::before {
    content: 'Легендарный коллекционный предмет';
}

.GC-k-w.--t::before {
    content: '';
}

.GC-k-h.--t::before {
    content: '';
}

.GC-k-as.--t::before {
    content: 'Предмет внешнего вида';
}

.GC-k-ras.--t::before {
    content: 'Редкий предмет внешнего вида';
}

.GC-k-has.--t::before {
    content: 'Высококачественный предмет внешнего вида';
}

.GC-k-uas.--t::before {
    content: 'Необычный предмет внешнего вида';
}

.GC-u-t.--t::before {
    content: 'уникальный';
}

.GC-s-d.--t::before {
    content: '';
}
.GC-s-s.--t::before {
    content: 'сувенирный';
}

.GC-s-n.--t::before {
    content: 'снят с производства';
}

.GC-c-c.--t::before {
    content: 'Коллекционный предмет';
}

.GC-c-r.--t::before {
    content: 'Редкий коллекционный предмет';
}

.GC-m-t.--t::before {
    content: 'Декоративная одежда';
}

.GC-d-t.--t::before {
    content: 'Не выпадает при смерти';
}

.GC-g-t.--t::before {
    content: 'Не уничтожается от повреждений';
}

.GC-count::before {
    color: rgba(168,168,168,1); text-shadow: 2px 2px 0px rgba(168,168,168,0.2);
    content: 'Количество: ';
}

.GC-p-t.--t::before {
    color: rgba(168,168,168,1); text-shadow: 2px 2px 0px rgba(168,168,168,0.2);
    content: 'Прочность: ';
}

.GC-e-t.--t::before {
    color: rgba(180,40,250,1); text-shadow: 2px 2px 0px rgba(180,40,250,0.1);
    content: 'С эффектом декоративного свечения';
}

.GC-j-0::before {
    content: ', чинится: ';
}

.GC-j-1::before {
    content: 'Меч, чинится: ';
}

.GC-j-2::before {
    content: 'Лопата, чинится: ';
}

.GC-j-3::before {
    content: 'Кирка, чинится: ';
}

.GC-j-4::before {
    content: 'Топор, чинится: ';
}

.GC-j-5::before {
    content: 'Мотыга, чинится: ';
}

.GC-j-6::before {
    content: 'Ножницы, чинятся: ';
}

.GC-j-7::before {
    content: 'Лук, чинится: ';
}

.GC-jj-1::after {
    content: 'Алмазная пыль';
}

.GC-jj-2::after {
    content: 'Слиток железа';
}

.GC-jj-3::after {
    content: 'Слиток золота';
}

.GC-jj-4::after {
    content: 'Палочка';
}

.GC-jj-5::after {
    content: 'Вишнёвая палочка';
}

.GC-j-8::before {
    content: 'Штангенциркуль, чинится: ';
}

.GC-organ-1::before {
    content: ' (голова)';
}

.GC-organ-2::before {
    content: ' (тело)';
}

.GC-organ-3::before {
    content: ' (штаны)';
}

.GC-organ-4::before {
    content: ' (обувь)';
}

.--none {
    display: none;
}

.comma::after {
    color: rgba(168,168,168,1); text-shadow: 2px 2px 0px rgba(168,168,168,0.2);
    content: ', ';
}

.comma:last-child::after {
    content: '';
}

div.grt {
    margin-bottom: 16px !important;
}

.GCC-1 {
    color: rgba(255, 255, 255,1); text-shadow: 2px 2px 0px rgba(255, 255, 255,0.2);
}

.GCC-2 {
    color: rgba(168,168,168,1); text-shadow: 2px 2px 0px rgba(168,168,168,0.2);
}

.GCC-3 {
    color: rgba(140, 159, 255,1); text-shadow: 2px 2px 0px rgba(140, 159, 255,0.2);
}

.GCC-4 {
    color: rgba(89, 60, 242,1); text-shadow: 2px 2px 0px rgba(89, 60, 242,0.2);
}

.GCC-5 {
    color: rgba(48, 80, 250,1); text-shadow: 2px 2px 0px rgba(48, 80, 250,0.2);
}

.GCC-6 {
    color: rgba(189, 165, 203,1); text-shadow: 2px 2px 0px rgba(189, 165, 203,0.2);
}

.GCC-7 {
    color: rgba(137, 93, 162,1); text-shadow: 2px 2px 0px rgba(137, 93, 162,0.2);
}

.GCC-8 {
    color: rgba(190, 35, 255,1); text-shadow: 2px 2px 0px rgba(190, 35, 255,0.2);
}

.GCC-9 {
    color: rgba(180,40,250,1); text-shadow: 2px 2px 0px rgba(180,40,250,0.1);
}

.GCC-10 {
    color: rgba(168,252,252,1); text-shadow: 2px 2px 0px rgba(168,252,252,0.2);
}

.GCC-11 {
    color: rgba(32, 176, 160,1); text-shadow: 2px 2px 0px rgba(32, 176, 160,0.2);
}

.GCC-12 {
    color: rgba(185, 216, 162,1); text-shadow: 2px 2px 0px rgba(185, 216, 162,0.2);
}

.GCC-13 {
    color: rgba(108, 165, 67,1); text-shadow: 2px 2px 0px rgba(108, 165, 67,0.2);
}

.GCC-14 {
    color: rgba(70, 153, 99,1); text-shadow: 2px 2px 0px rgba(70, 153, 99,0.2);
}

.GCC-15 {
    color: rgba(6, 165, 59,1); text-shadow: 2px 2px 0px rgba(6, 165, 59,0.2);
}

.GCC-16 {
    color: rgba(0, 170, 0,1); text-shadow: 2px 2px 0px rgba(0, 170, 0,0.2);
}

.GCC-17 {
    color: rgba(255, 20, 147,1); text-shadow: 2px 2px 0px rgba(255, 20, 147,0.2);
}

.GCC-18 {
    color: rgba(255, 36, 0,1); text-shadow: 2px 2px 0px rgba(255, 36, 0,0.2);
}

.GCC-19 {
    color: rgba(255,155,132,1); text-shadow: 2px 2px 0px rgba(255,155,132,0.2);
}

.GCC-20 {
    color: rgba(207, 106, 50,1); text-shadow: 2px 2px 0px rgba(207, 106, 50,0.2);
}

.GCC-21 {
    color: rgba(255, 117, 24,1); text-shadow: 2px 2px 0px rgba(255, 117, 24,0.2);
}